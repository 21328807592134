<template>
  <!-- 侧导航 -->
  <div>
    <div class="flex-row navbar">
      <img
        class="logo"
        src="../static/img/logo.png"
        @click="$router.push('/enterprise-all')"
        style="cursor: pointer;"
      />
      <span
        class="navBarTitle"
        @click="$router.push('/enterprise-all')"
        style="cursor: pointer;"
      >{{ $t("navbar.title") }}</span>
      <span
        class="navBarSubTitle"
        @click="$router.push('/enterprise-all')"
        style="cursor: pointer;"
      >{{ $t("navbar.subTitle") }}</span>
      <div class="flex-wrap"></div>
      <el-dropdown @command="langeSwitch" class="lang-view">
        <span class="el-dropdown-link">{{ lanage }}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="$t('navbar.langueEn')">
            {{
            $t("navbar.langueEn")
            }}
          </el-dropdown-item>
          <el-dropdown-item :command="$t('navbar.langueCn')">
            {{
            $t("navbar.langueCn")
            }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span v-if="isShow" style="margin-right: 20px;">
        <el-link :underline="false" type="primary">{{ $t("currentPosition") }}</el-link>
        <span v-show="lanage === 'English'" style="display: inline-block;width: 5px;"></span>
        <el-link
          :underline="false"
          class="el-link-default"
        >{{ roleId === "1" ? $t("loginPage.supplierRegister") : roleId === "2" ? $t("loginPage.investorRegister") : $t("loginPage.enterpriseRegister") }}</el-link>
        <span style="margin-top: 5px;">&nbsp;>&nbsp;</span>
        <el-link
          :underline="false"
          @click="$router.push(routerOne.path).catch(error => {})"
        >{{ $t(routerOne.title) }}</el-link>
        <span v-show="routerTwo">&nbsp;>&nbsp;</span>
        <el-link v-show="routerTwo" :underline="false">{{ $t("details") }}</el-link>
        <el-button class="btn-logout" @click="goLogout">{{ $t("navbar.tuiLohout") }}</el-button>
      </span>
      <!--<el-dropdown @command="menuSwich">
        <div class="flex-row">
          <el-avatar icon="el-icon-user-solid"></el-avatar>
          <span>{{ $t("navbar.myAccount") }}</span>
          <i class="el-icon-arrow-down" style="margin: 0 10px"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="menu-item" command="logout">{{
            $t("navbar.logout")
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>-->
    </div>
    <el-container>
      <el-menu
        v-if="sideShow"
        active-text-color="#81D8D0"
        :router="true"
        :default-active="'/' + this.$route.path.split('/')[1]"
      >
        <el-menu-item v-for="route in pathList" :key="route.path" :index="route.path">
          <i :class="route.icon"></i>
          <span slot="title">{{ $t(route.title) }}</span>
        </el-menu-item>
      </el-menu>
      <el-main>
        <div class="mainContain">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Storage from '../utils/storage';

export default {
  name: "enterprise",
  data() {
    return {
      pathList: [
        {
          icon: 'el-icon-menu',
          path: '/enterprise-all',
          title: 'adnavPage.overview'
        }, {
          icon: 'el-icon-user',
          path: '/financing-manage',
          title: 'adnavPage.supplierManagement'
        }, {
          icon: 'el-icon-document',
          path: '/mark-list',
          title: 'adnavPage.financingApplicationManagement'
        }, {
          icon: 'el-icon-document-copy',
          path: '/order-manage',
          title: 'menuI18n.OrderManage'
        }],
      lanage: '',
      roleId: '',
      routerOne: {
        icon: 'el-icon-menu',
        path: '/supply-all',
        title: 'adnavPage.overview'
      },
      routerTwo: false,
      isShow: true
    };
  },
  mounted() {
    this.isShow = !["/root", "/error-code", "/no-power", "/reject", "/review", "/wait", "/invitation-code", "/enterprise-create", "/company-create", "/invest-create"].includes(this.$route.path);
    if (this.isShow) {
      this.roleId = sessionStorage.getItem("roleId");
      if (window.performance.navigation.type === 1) {
        console.log("页面被刷新", this.$route.path);
        this.routerOne = this.pathList.find(item => {
          return this.$route.path.toString().includes(item.path);
        });
        this.routerTwo = this.$route.path.toString().length !== this.routerOne.path.length;
      }
    }
    this.lanage = Storage.getLocalItem('i18n') === 'zh-cn' ? '中文' : 'English';
  },
  methods: {
    menuSwich(command) {
      switch (command) {
        case 'person':

          break;
        case 'manage':

          break;
        case 'logout':
          this.$store.dispatch('auth/logoutSuccess', {
            username: '',
            authorization: ''
          });
          this.$router.replace({ path: '/login' });

          break;
        default:
          break;
      }
    },
    // 多语言切换
    langeSwitch(command) {
      // this.$message({ message: this.$t('navbar.changeLangePrompt') + command, type: 'success' });
      let local = command === '中文' ? 'zh-cn' : 'zh-en';
      Storage.setLocalItem('i18n', local);
      this.$i18n.locale = local;
      this.lanage = command;
      this.$store.dispatch('auth/language', local);
      window.location.reload();
    },
    // 退出登录
    goLogout() {
      this.$store.dispatch('auth/logoutSuccess');
      this.$router.replace({ path: '/login' });
    }
  },
  computed: {
    sideShow() {
      return this.$store.getters['auth/isSide'];
    }
  },
  watch: {
    $route(to, from) {
      if (this.isShow) {
        let isRoute = this.pathList.find(item => {
          return to.path.toString().includes(item.path);
        });
        if (!isRoute) return false;
        this.routerOne = isRoute;
        this.routerTwo = this.$route.path.toString().length !== this.routerOne.path.length;
      }
      console.log(from.path, to.path);
    }
  }
};
</script>
<style lang="scss" scoped>
.navbar {
  height: 60px;
  width: 100vw;
  min-width: 1200px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .logo {
    width: 38px;
    height: 30px;
    margin-left: 40px;
  }
  .navBarTitle {
    margin: 0 20px;
    margin-right: 10px;
    font-size: 30px;
    font-weight: bolder;
  }
  .navBarSubTitle {
    font-size: 24px;
  }
  .el-dropdown {
    margin: 0 10px;
    .el-avatar {
      margin-right: 10px;
    }
  }
}
.menu-item {
  width: 80px;
  text-align: center;
}
.el-container {
  position: relative;
  height: calc(100vh - 80px);
  min-width: 1200px;
  margin-top: 20px;
}
.el-main {
  padding-top: 0;
}
.mainContain {
  width: 1200px;
  margin: auto;
}
.el-menu {
  width: 240px;
}
</style>
